import React  from 'react'
import WorkHistoryTimeline from './workhistoryTimeline';
import HistoryTimeline from './historyTimeline';
import {Typography } from '@mui/material';
const History = ({data}) => {

  const education_data = data?.education_history
  const work_data = data?.work_history

  return (
    <div className="historyComp">
       <Typography variant='h4' sx={{fontSize: { lg: 30,md: 25,sm: 15, xs: 19},color:'grey', margin:'20px',fontFamily: "Quicksand", fontWeight:'bold'}}>
          Professional History 
        </Typography>
        { work_data &&
          <WorkHistoryTimeline  data={work_data} />
        }
        <hr style={{  borderColor: "black",}}/>
          <Typography variant='h4' sx={{fontSize: { lg: 30,md: 25,sm: 15, xs: 19},color:'grey', margin:'20px',fontFamily: "Quicksand", fontWeight:'bold'}}>
          Educational History
          </Typography>
          {education_data &&
            <HistoryTimeline data={education_data}/> 
          }
        
    </div>
  )
}

export default History