import React from "react";
import Typed from "react-typed";

const typingText = ({data}) => {
  const text = data
  return (
    <div className="TypingTextContainer">{}
      <div style={{ display: "flex" }}>
        <span style={{ color: "white" }}>{"<"}</span>
        <span className="typingTextCode">code</span>
        <span style={{ color: "white" }}>{">"}</span>
      </div>
      <span className="typingText" style={{ fontFamily: "Courier, monospace" }}>
        <Typed strings={text} typeSpeed={40} backSpeed={50} loop />
      </span>
      <div style={{ display: "flex" }}>
        <span style={{ color: "white" }}>{"</"}</span>
        <span className="typingTextCode">code</span>
        <span style={{ color: "white" }}>{">"}</span>
      </div>
    </div>
  );
};

export default typingText;
