import React from "react";
import { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TitleBanner from "./titleBanner";
import About from "./About";
import ReactGA from 'react-ga';

import Skills from "./Skills";

const Home = ({ data , link }) => {
  const typingText = data.typingText;
  const skills = data.skills;
  const aboutMe = data.aboutMe;

  const handleDownload = () => {
    window.open(link+"Kalpesh_Desale_Resume.pdf", "_blank");
    ReactGA.event({
      category: 'Button Click',
      action: 'Downloaded Resume',
      label: 'Download Resume',
    });
  };
  const about_section = useRef(null);
  const scrollToSection = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div style={{ animation: "transitionIn 0.75s" }}>
      <Box
        sx={{
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "2px",
          marginLeft: "2px",
          width: "100%",
        }}
      >
        <Box sx={{ minHeight: "100vh" }}>
          <TitleBanner data={typingText} callBack={handleDownload}/>
          <section
            id="section05"
            className="scroll"
            onClick={() => {
              scrollToSection(about_section);
            }}
          >
            <a>
              <p></p>
              <span></span>
            </a>
          </section>
        </Box>
        <hr style={{ borderColor: "black", width: "85%" }} />

        <Box
          ref={about_section}
          sx={{ paddingTop: "10px", marginBottom: "50px" }}
        >
          {aboutMe && <About data={aboutMe} />}
        </Box>

        <hr style={{ borderColor: "black", width: "85%" }} />
        <Box>{skills && <Skills data={skills} link={link}/>}</Box>
        <hr style={{ borderColor: "black", width: "85%" }} />

        <Box style={{}}>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                color: "white",
                border: "1px solid #ffab03",
                width: "150px",
                height: "40px",
                fontWeight: "bold",
                marginTop:'20px',
                fontSize: { xs: 12, sm: 12, md: 13, lg: 13, xl: 13 },
                marginLeft: "10px",
                marginRight: "10px",
                fontFamily: "Quicksand",
                "&:hover": { color: "grey" },
              }}
            >
              Contact Me
            </Button>
          </Link>
          <Button onClick={handleDownload}
              sx={{
                color: "white",
                border: "1px solid #ffab03",
                width: "160px",
                height: "40px",
                fontWeight: "bold",
                fontSize: { xs: 12, sm: 12, md: 13, lg: 13, xl: 13 },
                marginRight: "20px",
                marginTop:'20px',
                fontFamily: "Quicksand",
                "&:hover": { color: "grey" },
              }}
            >
             Download Resume
            </Button>
        </Box>


        <Box
          sx={{
            display: "flex",
            margin: " 20px 0",
            width: "100%",
            height: {
              xs: "50px",
              sm: "50px",
              md: "80px",
              lg: "80px",
              xl: "80px",
            },
            marginBottom: {
              xs: "15%",
              sm: "15%",
              md: "10%",
              lg: "20px",
              xl: "20px",
            },
            borderRadius: "5px",
            backgroundColor: "#1e1f1e",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              align="left"
              sx={{
                margin: "auto 0",
                fontSize: { xs: 12, sm: 12, md: 18, lg: 18, xl: 20 },
                color: "grey",
                marginLeft: {
                  xs: "10px",
                  sm: "10px",
                  md: "30px",
                  lg: "50px",
                  xl: "50px",
                },
                fontFamily: "Quicksand",
              }}
            >
              @2024 Kalpesh Desale
            </Typography>
            <Typography
              align="right"
              sx={{
                fontSize: { xs: 12, sm: 12, md: 18, lg: 18, xl: 20 },
                margin: "auto 0",
                color: "grey",
                marginRight: {
                  xs: "10px",
                  sm: "10px",
                  md: "30px",
                  lg: "50px",
                  xl: "50px",
                },
                fontFamily: "Quicksand",
              }}
            >
              Author : Kalpesh Desale
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
