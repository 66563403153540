import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const About = ({data}) => {
  return (
    <Box
      id="About"
      sx={{ width: "100%", margin: "auto", position: "relative" }}
    >
      <Typography
        variant="h4"
        align="center"
        sx={{ color: "white", marginBottom: "20px" }}
      >
        About Me
      </Typography>
      <Typography
        sx={{
          color: "grey",
          margin: { lg: "0 15%", md: "0 20%", sm: "0 5%", xs: "0 3%" },
          fontSize: { xs: 15, sm: 15, md: 20, lg: 20, xl: 25 },
          textAlign: { lg: "center", md: "left", sm: "left", xs: "Left" },
        }}
      >
        {data.paraOne}
      </Typography>
      <Typography
        sx={{
          color: "grey",
          margin: {
            lg: "20px 15%",
            md: "20px 20%",
            sm: "10px 5%",
            xs: "10px 3%",
          },
          fontSize: { xs: 15, sm: 15, md: 20, lg: 20, xl: 25 },
          textAlign: { lg: "center", md: "left", sm: "left", xs: "Left" },
        }}
      >
        {data.paraTwo}
      </Typography>
      <Box
        sx={{
          width: { lg: "80%", md: "80%", sm: "90%", xs: "90%" },
          display: "flex",
          justifyContent: "end",
          marginTop: "50px",
        }}
      >
        <Link to="/history" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              color: "white",
              border: "1px solid #ffab03",
              width: { lg: "150px", md: "150px", sm: "120px", xs: "120px" },
              height: { lg: "40px", md: "40px", sm: "30px", xs: "30px" },
              fontWeight: "bold",
              fontFamily: "Quicksand",
              "&:hover": { color: "grey" },
            }}
          >
            My History
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default About;
