import { Box, Typography} from '@mui/material'
import React from 'react'

const ProjectCard = ({data, link}) => {
  return (
    <Box
    key={data.id}
    sx={{
        width: { lg: '450px', md: '400px', sm: '400px', xs: '300px' },
       paddingBottom:'50px',
        color: 'grey', backgroundColor:'#1e1f1e',position:'relative',boxShadow:'-3px 3px 8px 0px #0f0f0f',
        margin:'25px 25px', borderRadius: '2%', transition: '0.3s',transform: 'scale(1)'
    }}>
    <Box id='top-container' sx={{maxHeightheight:'15%',paddingTop:'10px',}}>
        <Box>
            <hr style={{ borderColor: '#ffab03', width:'100%', marginTop:'25px', position: 'absolute', zIndex: '-1' }} />
            <Box sx={{ height:'60px', width:'60px', backgroundColor:'black',borderRadius:'50%', marginLeft:'20px', zIndex:'10'}}>
                <img
                    src={link + data.icon}
                    alt='icon'
                    style={{
                        borderRadius:'50%',
                        padding:'5px',
                        height: '50px',
                        width: '50px',

                    }}
                />
            </Box>
        </Box>
    </Box>
    <Box sx={{ height: '35%', width: '95%' ,margin:'10px auto' }}>
            <img
                src={link + data.img}
                alt={data.projectName}
                style={{ objectFit: 'cover', width: '100%', height: '100%' ,borderRadius:'2px',}}
            />
    </Box>
    <Box sx={{ marginTop: '05%', width: '80%', height: '60%', marginLeft:'5%' }}>
            <Box>
                <Typography align="left" sx={{ fontSize: { lg: 35, md: 23, sm: 15, xs: 25 }, color:'grey'}}>
                    {data.projectName}
                </Typography>
                <Typography align="left" sx={{ fontSize: { lg: 20, md: 18, sm: 18, xs: 16 }, color:'#ffab03', marginBottom:'10px'}}>
                {data.technologies}
                </Typography>
                <Typography
                    align="left"
                    sx={{ fontSize: { lg: 20, md: 20, sm:18, xs: 15 }}}>
                   {data.description}
                </Typography>
                <a href={data.github} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'white'}}>
                <Box sx={{ height:'40px', width:'40px', backgroundColor:'black',borderRadius:'50%', position:'absolute' ,right:'20px',
                         transition: '0.3s',
                         '&:hover': {
                            transition: '0.3s',
                            transform: 'scale(1.5)',
                        }}}>
                <img
                    src={'/images/github-icon.svg'}
                    alt='link'
                    style={{
                        height: '40px',
                        width: '40px',
                    }}
                />
            </Box>
            </a>
            </Box>
        </Box>
</Box>

  )
}

export default ProjectCard