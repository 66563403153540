import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const SkillCard = ({ data, link }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [skillPercentage] = useState(data.Percentage); // Set the skill percentage here

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      id={data.id}
      sx={{
        position: 'relative',
        height: { lg: '180px', md: '180px', sm: '80px', xs: '80px' },
        width: { lg: '180px', md: '180px', sm: '80px', xs: '80px' },
        boxShadow: '-3px 3px 8px 0px #0f0f0f',
        backgroundColor: '#1e1f1e',
        margin: '10px',
        borderRadius: '5px',
        padding: '5px',
        transition: '0.3s',
        '&:hover': {
          transition: '0.3s',
          transform: 'scale(1.1)',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: `${isHovered ? skillPercentage : 0}%`,
          backgroundColor: '#ffab03',
          borderRadius: '5px',
          zIndex: 1,
          transition: 'height 0.3s ease-in-out',
          transform: -`translateY(${isHovered ? -skillPercentage : 0}%)`,
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight:'bold',
          fontSize: '20px',
          zIndex: 2,
          color:'black',
          transition: 'opacity 0.3s',
          opacity: isHovered ? 1 : 0,
        }}
      >
        {skillPercentage}%
      </Box>
      <Box
        sx={{
          height: { lg: '120px', md: '120px', sm: '60px', xs: '60px' },
          width: { lg: '180px', md: '180px', sm: '80px', xs: '80px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={link + data.icon}
          alt={data.title}
          style={{
            height: '50px',
            width: '50px',

          }}
        />
      </Box>
      <Box sx={{}}>
        <Typography
          sx={{
            color: 'white',
            marginBottom: '15px',
            fontSize: { lg: 20, md: 20, sm: 12, xs: 12 },
          }}
        >
          {data.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default SkillCard;
