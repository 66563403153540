import React, { useState, useEffect } from 'react';
import './css/CustomCursor.css';
import { Box } from '@mui/material';

const CustomCursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [showRing, setShowRing] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });

      // Update circle position with slight delay
      setTimeout(() => {
        setCirclePosition({ x: e.clientX, y: e.clientY });
      }, 1);

      // Show the ring with a greater delay
      setTimeout(() => {
        setShowRing(true);
      }, 1);
    };

    const handleMouseStop = () => {
      // Reset circle position to center of the ring when cursor stops
      setCirclePosition(cursorPosition);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseStop);
    document.addEventListener('mouseenter', () => setShowRing(true));

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseStop);
      document.removeEventListener('mouseenter', () => setShowRing(true));
    };
  }, [cursorPosition]);

  return (
    <Box className="custom-cursor">
      <Box
        className="circle"
        style={{
          transform: `translate(${circlePosition.x}px, ${circlePosition.y}px)`,
        }}
      ></Box>
      {showRing && (
        <Box
          className="ring"
          style={{
            transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
          }}
        ></Box>
      )}
    </Box>
  );
};

export default CustomCursor;
