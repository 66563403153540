import React, { useEffect, useState } from "react";
import "./App.css";
import "./components/css/styles.css";
import "./components/css/timeline.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/home";
import RighSideBar from "./components/righSideBar";
import Projects from "./components/projects";
import Contact from "./components/contact";
import History from "./components/history";
import CustomCursor from "./components/customCursor";
import { Box } from "@mui/material";
import Loading from "./components/Loading.jsx";
import LocalData from "./data/data.json"
import ReactGA from 'react-ga';

function App() {
  const [repoData, setRepoData] = useState(undefined);
  const location = useLocation();
  ReactGA.initialize('G-R023PY49YK');
  
  useEffect(() => {
    const fetchData = async () => {
      const timestamp = new Date().getTime();
      try {
        const response = await fetch(
          `https://kalpesh-portfolio-data.s3.ap-south-1.amazonaws.com/data.json?timestamp=${timestamp}`,
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        ///update this
        console.log("fetching data from repo.")
        setRepoData(data);
        console.log("data",data)
      } catch (error) {
        setRepoData(LocalData);
        console.error("Error fetching image data:", error);
      }
    };
  
    setTimeout(() => {
      fetchData();
    }, 600);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const homeData = repoData?.homeData;
  const projectsData = repoData?.projectsData;
  const historyData = repoData?.historyData;
  const socialData = repoData?.socialData;
  const repoLink = repoData?.repoLink;

  if (!repoData) {
    return <Loading />;
  } else {
    return (
      <div className="App" style={{ display: "flex" }}>
        <CustomCursor />
        <RighSideBar />
        <Box
          sx={{
            width: { xs: "90%", sm: "92%", md: "95%", lg: "95%", xl: "95%" },
          }}
        >
          <Routes>
            <Route
              path="/"
              element={<Home data={homeData} link={repoLink} />}
            />
            <Route
              path="/projects"
              element={<Projects data={projectsData} link={repoLink} />}
            />
            <Route
              path="/contact"
              element={<Contact data={socialData} link={repoLink} />}
            />
            <Route path="/history" element={<History data={historyData} />} />
          </Routes>
        </Box>
      </div>
    );
  }
}

export default App;
