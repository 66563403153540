import { Box, Typography } from "@mui/material";
import React from "react";
import ProjectCard from "./Card";

const projects = ({ data , link}) => {
  const list = data;

  return (
    <Box sx={{ width: "100%", animation: "transitionIn 0.75s" }}>
      <Box sx={{ width: "70%", margin: "0 auto" }}>
        <Typography
          align="left"
          sx={{
            fontSize: { xs: 35, sm: 35, md: 35, lg: 50, xl: 50 },
            marginLeft: "10px",
            color: "white",
          }}
        >
          Projects
        </Typography>
        <Typography
          align="left"
          sx={{
            fontSize: { xs: 15, sm: 15, md: 35, lg: 30, xl: 30 },
            color: "grey",
          }}
        >
          Check out some of my latest work and projects.
        </Typography>
      </Box>
      <hr style={{ borderColor: "black", width: "85%" }} />

      <Box
        sx={{
          width: { lg: "95%", md: "90%", sm: "100%", xs: "100%" },
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {list.map((item) => {
          return (
            <div>
              <ProjectCard data={item} link={link} />
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default projects;
