import React, { useState, useRef } from "react";
import { MdMessage } from "react-icons/md";
import { HiMiniArrowUturnRight } from "react-icons/hi2";
import "./css/contactform.css";
import { TextField, Button, Box, Typography } from "@mui/material";
import emailjs from "@emailjs/browser";

const Contact = ({ data , link}) => {
  const socialData = data
  const emailservice = data.Email
  const [isRotated, setIsRotated] = useState(false);

  const handleFlip = () => {
    setIsRotated(!isRotated);
  };

  const form = useRef();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Send");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      alert("Please Enter Name!");
      return;
    }
    if (!email) {
      alert("Please Enter Email!");
      return;
    }
    if (!subject) {
      alert("Please Enter Subject!");
      return;
    }
    if (!message) {
      alert("Please Enter Message!");
      return;
    }
    setStatus("Sending...");
    emailjs
      .sendForm(
        emailservice.serviceId,
        emailservice.templateId,
        form.current,
        emailservice.publicKey
      )
      .then(
        (result) => {
          alert("Message Sent");
          console.log(result.text);
        },
        (error) => {
          alert(error);
          console.log(error.text);
        }
      );
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setStatus("Send");
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        animation: "transitionIn 0.75s",
      }}
    >
      <div className={`father ${isRotated ? "rotate" : ""}`}>
        <div className="front">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#1e1f1e",
              borderRadius: "10px",
              position: "relative",
              paddingBottom: "50px",
              height: { lg: "500px", md: "500px", sm: "500px", xs: "500px" },
            }}
          >
            <Box
              sx={{
                margin: "0 auto",
                height: "15%",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: 40, md: 35, sm: 22, xs: 20 },
                  fontFamily: "Quicksand",
                }}
              >
                CONTACT
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: 20, md: 20, sm: 15, xs: 15 },
                  margin: "10px 50px",
                  color: "grey",
                }}
              >
                Get in touch with me via social media or email.
              </Typography>
            </Box>
            <hr style={{ borderColor: "black", width: "90%" }} />
            <Box sx={{ display: "flex", flexGrow: 1, margin: "20px" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    lg: "1fr 1fr",
                    md: "1fr 1fr",
                    sm: "1fr 1fe",
                    xs: "1fe",
                  },
                  gap: { lg: "100px", md: "100px", sm: "50px", xs: "10px" },
                  marginRight: { lg: 0, md: 0, sm: "50px", xs: "100px" },
                  height: "100%",
                  width: "100%",
                }}
              >
                <a href={socialData?.Linkedin.link} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'white'}}>
                <Box sx={{ display: "flex", alignItems: "center",'&:hover': {
                    transition: '0.3s',
                    transform: 'scale(1.1)',
                  }, }}>
                  <img
                    className="social-icon"
                    src={link + socialData?.Linkedin.icon}
                    alt="Linkedin icon"
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      marginLeft: "10px",
                      fontSize: { lg: 25, md: 25, sm: 20, xs: 20 },
                    }}
                  >
                    Linkedin
                  </Typography>
                </Box>
                </a>
                <a href={socialData?.Twitter.link} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'white'}}>
                <Box sx={{ display: "flex", alignItems: "center",'&:hover': {
                    transition: '0.3s',
                    transform: 'scale(1.1)',
                  }, }}>
                  <img
                    className="social-icon"
                    src={link + socialData?.Twitter.icon}
                    alt="Twitter icon"
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      marginLeft: "10px",
                      fontSize: { lg: 25, md: 25, sm: 20, xs: 20 },
                    }}
                  >
                    Twitter
                  </Typography>
                </Box>
                </a>

                <a href={socialData?.Instagram.link} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'white'}}>
                <Box sx={{ display: "flex", alignItems: "center", '&:hover': {
                    transition: '0.3s',
                    transform: 'scale(1.1)',
                  }, }}>
                  <img
                    className="social-icon"
                    src={link + socialData?.Instagram.icon}
                    alt="Instagram icon"
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      marginLeft: "10px",
                      fontSize: { lg: 25, md: 25, sm: 20, xs: 20 },
                    }}
                  >
                    Instagram
                  </Typography>
                </Box>
                </a>
                <a href={socialData?.Github.link} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'white'}}>
                <Box sx={{ display: "flex", alignItems: "center", '&:hover': {
                    transition: '0.3s',
                    transform: 'scale(1.1)',
                  },}}>
                  <img
                    className="social-icon"
                    src={link + socialData?.Github.icon }
                    alt="Github icon"
                  />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: { lg: 25, md: 25, sm: 20, xs: 20 },
                    }}
                  >
                    Github
                  </Typography>
                </Box>
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                color: "grey",
                height: "50px",
                fontWeight: "bold",
                position: "absolute",
                bottom: "20px",
                right: "10%",
                fontFamily: "Quicksand",
                "&:hover": {
                  transition: "0.3s",
                  transform: "scale(1.5)",
                },
              }}
              onClick={handleFlip}
            >
              <MdMessage />
            </Box>
          </Box>
        </div>
        <div className="back">
          <Box
            id="backbox"
            sx={{
              backgroundColor: "#1e1f1e",
              height: "100%",
              borderRadius: "10px",
            }}
          >
            <Box style={{ padding: "20px" }}>
              <Typography sx={{ fontSize: { lg: 40, md: 35, sm: 22, xs: 20 }, fontFamily: "Quicksand",}}>
                Write Me
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: 20, md: 20, sm: 15, xs: 15 },
                  margin: "10px 50px",
                  color: "grey"
                }}
              >
                Or you can send me a mail.
              </Typography>
            <hr style={{ borderColor: "black", width: "90%" }} />
              <Box
                sx={{
                  width: { lg: "80%", md: "80%", sm: "90%", xs: "100%" },
                  margin: "0 auto",
                  marginTop:"-20px"
                }}
              >
                <form ref={form} onSubmit={handleSubmit}>
                  <TextField
                    inputProps={{ style: { color: "grey" } }}
                    InputLabelProps={{
                      sx: {
                        fontSize: { lg: 20, md: 20, sm: 20, xs: 16 },
                        color: "grey",
                        paddingLeft: "15px",
                        fontFamily: "Quicksand",
                      },
                    }}
                    fullWidth
                    variant="standard"
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    inputProps={{ style: { color: "grey" } }}
                    InputLabelProps={{
                      sx: {
                        fontSize: { lg: 20, md: 20, sm: 20, xs: 16 },
                        color: "grey",
                        paddingLeft: "15px",
                        fontFamily: "Quicksand",
                      },
                    }}
                    fullWidth
                    variant="standard"
                    label="Subject"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    inputProps={{ style: { color: "grey" } }}
                    InputLabelProps={{
                      sx: {
                        fontSize: { lg: 20, md: 20, sm: 20, xs: 16 },
                        color: "grey",
                        paddingLeft: "15px",
                        fontFamily: "Quicksand",
                      },
                    }}
                    variant="standard"
                    fullWidth
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    type="email"
                  />
                  <TextField
                    inputProps={{ style: { color: "grey" } }}
                    InputLabelProps={{
                      sx: {
                        fontSize: { lg: 20, md: 20, sm: 20, xs: 16 },
                        color: "grey",
                        paddingLeft: "15px",
                        fontFamily: "Quicksand",
                      },
                    }}
                    fullWidth
                    variant="standard"
                    label="Message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        mt: 4,
                        backgroundColor: "#ffab03",
                        color: "black",
                        fontFamily: "Quicksand",
                      }}
                    >
                      {status}
                    </Button>
                    <Box
                      sx={{
                        color: "grey",
                        height: "50px",
                        fontWeight: "bold",
                        marginTop: "35px",
                        fontFamily: "Quicksand",
                        "&:hover": {
                          transition: "0.3s",
                          transform: "scale(1.5)",
                        },
                      }}
                      onClick={handleFlip}
                    >
                      <HiMiniArrowUturnRight />
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Contact;
