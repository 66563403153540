import {Typography } from '@mui/material'
import React from 'react'


const HistoryTimeline = ({data}) => {
   
    const edData = data
  return (
    <div >
        <section className="timeline-section">
            <div className="timeline-items">
                
                {
                    
                    edData.map((data)=>
                    {
                        return(
                            
                            <div key={data.id} className="timeline-item">
                                <div className="timeline-dot">
                                </div>
                                    <div className="timeline-content">
                                        <div style={{display :'flex',marginBottom:'10px'}}>
                                            <div >
                                                <Typography variant='h5'sx={{  fontSize: {
                                                                lg: 25,
                                                                md: 25,
                                                                sm: 20,
                                                                xs: 15
                                                                }}}>
                                                {data.courseName}
                                                </Typography>
                                            </div>
                                        </div> 
                                        <div style={{marginLeft:'15px'}}>
                                                <Typography variant='h6' sx={{fontSize: {
                                                                lg: 20,md: 25,sm: 15, xs: 12},fontFamily: "Quicksand", fontWeight:'bold'}} >
                                                    {data.uniName} 
                                                </Typography>
                                                    <div style={{display:'flow'}}>
                                                        <Typography  align='left' sx={{fontSize: {
                                                                lg: 20,md: 25,sm: 15, xs: 12}, marginTop:'2%',fontFamily: "Quicksand"}}>
                                                             {data.instName}
                                                        </Typography>
                                                        <div className='timeline-date' style={{color:'#ffab03' , fontWeight:'bold'}}>
                                                            {data.startDate} -  {data.endDate}
                                                        </div>       
                                                    </div> 
                                        </div> 
                                </div>
                            </div>
                                        
                        )
                    })  
                }


            </div>
        </section>
    </div>
  )
}

export default HistoryTimeline