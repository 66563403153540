import React from 'react'
import '../components/css/loading.css';

const Loading = () => {
  return (
    <div className='loading-background'>
        <div className="loading-container">
        <div className="loading-text">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
        </div>
        </div>
    </div>
  )
}

export default Loading