import {Typography } from '@mui/material'
import React from 'react'


const WorkHistoryTimeline = ({data}) => {
   
    const edData = data
  return (
    <div >
        <section className="timeline-section">
            <div className="timeline-items">
                
                {
                    
                    edData.map((data)=>
                    { 
                        return(
                            
                                <div key={data.id} className="timeline-item">
                                    <div className="timeline-dot">
                                    </div>
                                    <div className="timeline-content">
                                            <div style={{display :'flex' ,alignContent:'center'}}>
                                                <div >
                                                    <Typography variant='h4' align='left' sx={{fontSize: {
                                                                lg: 30,md: 25,sm: 15, xs: 19}, marginTop:'2%'}}>
                                                   {data.role}
                                                    </Typography>
                                                    <Typography variant='h5' align='left' sx={{fontSize: {
                                                                lg: 25,md: 25,sm: 15, xs: 16}}}>
                                                    {data.companyName} 
                                                    </Typography>
                                                <div>
                                            </div>
                                                <div style={{display:'flow', marginTop:'2%'}}>
                                                    <div className="descContent">
                                                    <Typography variant='h7' align='left' sx={{fontSize: {
                                                                lg: 16,md: 14,sm: 14, xs: 12},fontFamily: "Quicksand"}}>
                                                             {data.description}
                                                        </Typography>
                                                    </div>
                                                        <div className='timeline-date'style={{color:'#eda81c' , fontWeight:'bold'}} >
                                                            {data.startDate} -  {data.endDate}
                                                        </div>       
                                                </div> 
                                                    
                                                </div>          
                                            </div>  
                                            
                                    </div>
                                </div>
                          
                        )
                    })  
                }


            </div>
        </section>
    </div>
  )
}

export default WorkHistoryTimeline