import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Link, useLocation } from "react-router-dom";
const RighSideBar = () => {
  const [rbTitle, SetRBTitle] = useState("Home");
  const [isOpen, SetIsOpen] = useState(false);
  const location = useLocation();

  const menu = [
    {
      id: 1,
      name: "HOME",
      link: "/",
    },
    {
      id: 2,
      name: "PROJECTS",
      link: "/projects",
    },
    {
      id: 3,
      name: "HISTORY",
      link: "/history",
    },
    {
      id: 4,
      name: "CONTACT",
      link: "/contact",
    },
  ];

  function getNameFromLink(menu, link) {
    const menuItem = menu.find((item) => item.link === link);
    return menuItem ? menuItem.name : null;
  }

  useEffect(() => {
    SetRBTitle(getNameFromLink(menu, location.pathname));
  }, [location,menu]);

  if (isOpen) {
    return (
      <div className="rightBar">
        <div style={{ backgroundColor: "#262626" }}>
          <CancelOutlinedIcon
            sx={{
              color: "Grey",
              marginRight: "80%",
              marginTop: "10px",
              height: "35px",
              width: "50px",
            }}
            onClick={() => {
              SetIsOpen(false);
            }}
          />
          <hr style={{ borderColor: "black" }} />
        </div>
        <div style={{ marginTop: "40%" }}>
          {menu.map((item) => {
            return (
              <div key={item.id} className="rbarButtons">
                <Box sx={{ display: "flex" }}>
                  <Link
                    to={item.link}
                    onClick={() => {
                      SetIsOpen(!isOpen);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: 22, md: 22, sm: 20, xs: 20 },
                        fontFamily: "Quicksand",
                        color: "Grey",
                        marginLeft: "10px",
                        marginTop: "10px",
                        "&:hover": { color: "white" },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Link>
                </Box>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="rightBarClosed">
      <div style={{ backgroundColor: "#262626" }}>
        <MenuIcon
          sx={{
            color: "Grey",
            marginTop: "10px",
            height: "50px",
            width: "50px",
          }}
          onClick={() => {
            SetIsOpen(true);
          }}
        />
        <hr style={{ borderColor: "black" }} />
      </div>
      <Typography
        sx={{
          fontSize: { lg: 35, md: 30, sm: 25, xs: 25 },
          marginLeft: { lg: "20px", md: 0, sm: 0, xs: 0 },
          color: "#ffab03",
          display: "flex",
          justifyContent: "center",
          marginTop: { lg: "200px", md: "200px", sm: "200px", xs: "200px" },
          transform: "rotate(90deg)",
          transformOrigin: "left bottom",
          whiteSpace: "nowrap",
          fontFamily: "Quicksand",
        }}
      >
        {rbTitle}
      </Typography>
    </div>
  );
};

export default RighSideBar;
