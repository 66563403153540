import React from 'react'
import {Box ,Typography, Button} from '@mui/material';
import TypingText from './typingText';
const titleBanner = ({data, callBack}) => {

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center', 
      position:'relative',
  }}>
    <Box  sx={{
          width: { xs: '95%', sm: '85%', md: '80%', lg: '85%', xl: '85%' },
          borderRadius: '10px',
          height:{ xs: '70vh', sm: '70vh', md: '75vh', lg: '75vh', xl: '75vh'},
          padding: '10px',
          margin: '10px',
          marginTop: { xs: '10%', sm: '10%', md: '10%', lg: '2%', xl: '2%'},
          backgroundImage: 'linear-gradient(rgba(0, 22, 95, 0.2), rgba(14, 0, 76, 0.302)), url("../../images/HomePageBG.jpg")',
          backgroundSize: 'cover',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          boxShadow: '-20px 20px 10px 5px rgba(0, 0, 0, 0.30)',
      }}>

          <Box sx={{position:'relative', Width:'70%', height:'80%',alignItems:'center', margin:'0 auto'}}>
              <Box id="banner_text" sx={{width:{xs:'60%',sm: '60%',md:'40%', lg:'45%', xl:'45%'},
                                        position:'absolute',top:{xs:'40vh',sm: '40vh',md:'20vh', lg:'20vh', xl:'20vh'},
                                        left:{xs:'20%',sm: '20%',md:'0', lg:'0', xl:'0'}}}>

                  <Typography align='right'  sx={{color:'white',border:'10px',
                                                marginRight:'10px',
                                                textShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
                                                fontSize: { xs: 20, sm: 20, md: 25, lg: 30, xl: 30 },
                                                textAlign:{ xs: 'left', sm: 'left', md: 'right', lg: 'right', xl: 'right' },
                                                fontFamily: "Quicksand"}} >
                                  Hi, I'm
                    </Typography>        
                  <Typography align='right' sx={{
                          margin:'-10px',marginRight:'10px',
                          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.5)',
                          fontWeight: 'bold',
                          fontFamily: 'Quicksand',
                          color: '#ffab03',
                          textAlign:{ xs: 'center', sm: 'center', md: 'right', lg: 'right', xl: 'right' },
                          fontSize: { xs: 35, sm: 35, md: 35, lg: 70, xl: 70}
                      }}>
                          Kalpesh.
                    </Typography>
                  <Typography align='right' sx={{color:'white', border:'10px',marginRight:'10px',
                                              fontFamily: "Quicksand",
                                              textAlign:{ xs: 'center', sm: 'center', md: 'right', lg: 'right', xl: 'right' },
                                              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.5)',
                                              fontSize:{xs: 20, sm: 28,md:35, lg:50, xl:50}}}>
                    Welcome
                    </Typography>
                  <Typography align='right'  sx={{color:'white', border:'10px',marginRight:'10px',
                                              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.5)',
                                              textAlign:{ xs: 'center', sm: 'center', md: 'right', lg: 'right', xl: 'right' },
                                              fontSize:{xs: 15, sm: 20,md:25, lg:30, xl:30},
                                              fontFamily: "Quicksand"}} >
                                  to my portfolio
                    </Typography>                          
              </Box>
              <Box sx={{width:{xs:'100%',sm: '100%',md:'60%', lg:'55%', xl:'55%'},  position:'absolute',
                                right:{xs:'-20%',sm: '-30%',md:'0', lg:'0', xl:'0'},
                               top:{xs:'5%',sm: '5%',md:'10%', lg:'0%', xl:'0%'}}} >
                    <Box  id="banner_profile pic" sx={{width: {xs:'180px',sm: '200px',md:'250px', lg:'350px', xl:'350px'}}}>
                      <img
                          src={'/images/Profile.png'}
                          alt="profile_pic"
                          style={{ height: '100%', width: '100%', }}/>  
                    </Box>
              </Box>
          </Box>



          <Box>

                    <Box sx={{paddingLeft:'10px',position:'absolute', bottom:'5vh',
                              width:'80%',
                              marginTop: { xs: '80px', sm: '120px', md: '150px', lg: '150px', xl: '250px'}}}>

                        {data&&
                          <TypingText data={data}/>
                        }
                        

                        
                    </Box>   
                    <Button sx={{color: "white", border: "1px solid #ffab03",  width: "180px", height: "50px", fontWeight: "bold",
                                      marginRight:'20px',position:'absolute', bottom:'8vh', right:'10%',
                                      fontFamily: "Quicksand",
                                      display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'},
                                      "&:hover": { color: "grey" }}} 
                                      onClick={callBack}
                                      >
                            Download Resume
                    </Button> 
          </Box>
    </Box>
    </Box>
  )
}

export default titleBanner