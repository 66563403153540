import React from 'react'
import { Box, Button, Typography } from "@mui/material";
import SkillCard from "./skillCard";
import { Link } from "react-router-dom";
const Skills = ({data, link}) => {


  return (
    <Box sx={{ width: "100%", margin: "20px 0" }}>
    <Typography variant="h4" sx={{ color: "white", width: "100%" }}>
      My Skills
    </Typography>
    <Box
      sx={{
        width: { lg: "90%", md: "90%", sm: "95%", xs: "95%" },
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        margin: "0 auto",
        marginTop: "20px",
      }}
    >
      {data.map((skill) => {
    return (
      <SkillCard key={skill.id} data={skill} link={link}/>
    );
  })}
      

    </Box>
    <Box
      sx={{
        width: { lg: "80%", md: "80%", sm: "90%", xs: "90%" },
        display: "flex",
        justifyContent: "end",
        marginTop: "50px",
      }}
    >
      <Link to="/projects" style={{ textDecoration: "none" }}>
        <Button
          sx={{
            color: "white",
            border: "1px solid #ffab03",
            width: { lg: "150px", md: "150px", sm: "100px", xs: "100px" },
            height: { lg: "40px", md: "40px", sm: "30px", xs: "30px" },
            fontWeight: "bold",
            fontFamily: "Quicksand",
            "&:hover": { color: "grey" },
          }}
        >
          My Work
        </Button>
      </Link>
    </Box>
  </Box>

  )
}

export default Skills